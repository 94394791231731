// src/components/GoogleCallback.js
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { login } = useAuth();

  useEffect(() => {
    const handleCallback = () => {
      try {
        const token = searchParams.get('token');
        const userDetailsStr = searchParams.get('userDetails');
        const error = searchParams.get('error');

        if (error) {
          throw new Error(decodeURIComponent(error));
        }

        if (!token || !userDetailsStr) {
          throw new Error("Missing authentication data");
        }

        const userDetails = JSON.parse(decodeURIComponent(userDetailsStr));
        login(userDetails, token);
        
        // Navigate to loader page
        navigate("/loader");
        
        // After delay, redirect to home
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      } catch (err) {
        console.error("Error handling callback:", err);
        navigate("/login");
      }
    };

    handleCallback();
  }, [navigate, searchParams, login]);

  return null;
};

export default GoogleCallback;