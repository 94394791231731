import React, { useEffect, lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'; // Add AuthProvider import
import ProtectedRoute from './components/ProtectedRoute';
import AdminAccess from './components/AdminAccess';
import { loadGoogleAnalytics, logPageView } from './utils/analytics';
import BookingPage from './pages/BookingPage';
import PurchaseConfirmationPage from './pages/BookingConfirmationPage';
import Packages from './pages/PackagesPage';
import AdminAccessPage from './pages/AdminPanelAccess';
import AdminPage from './pages/AdminPanel';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/FAQ';
import GoogleCallback from './pages/GoogleCallBack';
// import QRScanner from './pages/QRScanner';

// Lazy loading pages for code-splitting
const SignupPage = lazy(() => import('./pages/SignupPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ForgotPage = lazy(() => import('./pages/ForgotPage'));
const ResetPage = lazy(() => import('./pages/ResetPage'));
const ResetSuccessPage = lazy(() => import('./pages/ResetSuccessPage'));
const LoaderPage = lazy(() => import('./pages/NewLoader'));
const VerificationPage = lazy(() => import('./pages/VerificationPage'));
const VerifySuccessPage = lazy(() => import('./pages/VerifySuccessPage'));
const Home = lazy(() => import('./pages/Home'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const Discover = lazy(() => import('./pages/Discover'));
const EventDetails = lazy(() => import('./pages/EventDetails'));
const EventPage = lazy(() => import('./pages/EventPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const AdminEventPage = lazy(() => import('./pages/AdminEventPage'));
const CookiesPolicyPage = lazy(() => import('./pages/CookiesPolicyPage'));
const RefundPolicyPage = lazy(() => import('./pages/RefundPolicyPage'));
// We will add this content when required
const ContactPage = lazy(() => import('./pages/ContactPage'));
//const WebsiteTermsOfUsePage = lazy(() => import('./pages/WebsiteTermsOfUsePage'));
//const ServiceLegalAgreementPage = lazy(() => import('./pages/ServiceLegalAgreementPage'));

// Layout Component with Scroll Restoration and Page View Logging
const Layout = React.memo(() => {
  const location = useLocation();

  useEffect(() => {
    const forceScrollToTop = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
    // Delay scroll to ensure DOM rendering is complete
    const timeout = setTimeout(forceScrollToTop, 50);
    return () => clearTimeout(timeout);
  }, [location.pathname, location.search]);

  return <Outlet />;
});

// Router Configuration
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Navigate to="/home" replace /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/forgot', element: <ForgotPage /> },
      { path: '/reset', element: <ResetPage /> },
      { path: '/reset1', element: <ResetSuccessPage /> },
      { path: '/loader', element: <LoaderPage /> },
      { path: '/verify', element: <VerificationPage /> },
      { path: '/verify1', element: <VerifySuccessPage /> },
      { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
      { path: '/terms-and-conditions', element: <TermsPage /> },
      { path: '/home', element: <Home /> },
      { path: '/account', element: <AccountPage /> },
      { path: '/discover', element: <Discover /> },
      { path: '/packages', element: <Packages /> },
      { path: '/discover/:categoryID', element: <Discover /> },
      { path: '/purchase-confirmation', element: <ProtectedRoute><PurchaseConfirmationPage /></ProtectedRoute> },
      { path: '/admin-access', element: <AdminAccessPage/> },
      { path: '/admin', element: <AdminAccess><AdminPage/></AdminAccess> },
      { path: '/add-event', element: <EventPage /> },
      { path: '/cookies-policy', element: <CookiesPolicyPage /> },
      { path: '/refund-policy', element: <RefundPolicyPage /> },
      //will add these pages when required
      { path: '/contact', element: <ContactPage /> },
      //{ path: '/website-terms-of-use', element: <WebsiteTermsOfUsePage /> },
      //{ path: '/service-legal-agreement', element: <ServiceLegalAgreementPage /> },
      {
        path: '/list-event',
        element: (
          //removing protected route as there is some problem 
          <ProtectedRoute>
            <AdminEventPage />
          </ProtectedRoute>
        ),
      },
      { path: '/event-details/:eventName', element: <EventDetails /> },
      { path: '/purchase/:eventName', element: <ProtectedRoute><BookingPage /></ProtectedRoute> },
      { path: '/aboutus', element: <AboutUs/>},
      { path: '/faq', element:<FAQ/>},
      { path: '/listme', element:<AdminEventPage/>},
      { path: "/google/callback" , element:<GoogleCallback /> }
    ],
  },
]);

const App = () => {
  useEffect(() => {
    // Delay GA initialization until after critical resources are loaded
    const initializeAnalytics = () => {
      loadGoogleAnalytics()
        .then(() => {
          // console.log('Google Analytics initialized.');
          logPageView(); // Log the initial page view
        })
        .catch((error) => {
          console.error('Analytics initialization failed:', error);
        });
    };

    if ('requestIdleCallback' in window) {
      requestIdleCallback(initializeAnalytics);
    } else {
      setTimeout(initializeAnalytics, 2000); // Fallback delay
    }
  }, []);

  return (
    <AuthProvider>
      <Suspense fallback={<LoaderPage />}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  );
};

export default App;