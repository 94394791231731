import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDashboardLogin from '../assets/AdminDashboardLogin.svg';
import LogoText from '../assets/LogoText.svg';
import UserIcon from '../assets/user-icon.svg';
import LockIcon from '../assets/lock-icon.svg';

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
    setError(''); // Clear error when user types
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${apiUrl}/admin-event/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Login failed');
      }

      // Store token and orgId in localStorage
      localStorage.setItem('tokenAdmin', data.tokenAdmin);
      localStorage.setItem('orgId', data.orgId);

      // Redirect to dashboard
      navigate('/admin');
      
    } catch (err) {
      setError(err.message || 'Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen relative flex flex-col items-center justify-center">
      {/* Background SVG */}
      <img
        src={AdminDashboardLogin}
        alt="Background Pattern"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Content overlay */}
      <div className="relative z-10 flex flex-col items-center w-full max-w-md px-6">
        {/* Logo */}
        <div className="mb-8">
          <img
            src={LogoText}
            alt="Logo moqah"
            className="w-[140px] h-[98px]"
          />
        </div>

        {/* Error message */}
        {error && (
          <div className="w-[300px] mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        {/* Login Form */}
        <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
          <div className="relative w-[300px] mb-4">
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-20">
              <img
                src={UserIcon}
                alt=""
                className="w-5 h-5 object-contain"
              />
            </div>
            <input
              id="username"
              type="text"
              required
              value={formData.username}
              onChange={handleChange}
              className="w-[300px] h-[45px] pl-12 pr-3 bg-transparent border border-white rounded-sm shadow-sm 
                       text-white placeholder-[#CCCCCC] text-[12px] leading-[20px]
                       focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent"
              placeholder="USERNAME"
              style={{
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none'
              }}
            />
          </div>

          <div className="relative w-[300px]">
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-20">
              <img
                src={LockIcon}
                alt=""
                className="w-5 h-5 object-contain"
              />
            </div>
            <input
              id="password"
              type="password"
              required
              value={formData.password}
              onChange={handleChange}
              className="w-[300px] h-[45px] pl-12 pr-3 bg-transparent border border-white rounded-sm shadow-sm 
                       text-white placeholder-[#CCCCCC] text-[12px] leading-[20px]
                       focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent"
              placeholder="PASSWORD"
              style={{
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none'
              }}
            />
          </div>

          <div className="mt-12 flex flex-col items-center">
            <button
              type="submit"
              disabled={isLoading}
              className="w-[300px] h-[45px] flex justify-center items-center border border-white rounded-sm 
                       text-[16px] font-semibold leading-[20px] text-[#244BC5] bg-white
                       hover:bg-white/90 transition-colors duration-200
                       focus:outline-none focus:ring-2 focus:ring-white
                       disabled:opacity-50 disabled:cursor-not-allowed"
              style={{
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none'
              }}
            >
              {isLoading ? 'LOGGING IN...' : 'LOGIN'}
            </button>
            <p className="text-white text-[12px] mt-6 cursor-pointer hover:underline">
              Facing any Issues? Contact Us!
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;