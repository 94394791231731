import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PdfTicket from "../components/PdfTicket";
import { pdf } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";

const PurchaseConfirmationPage = () => {
  const location = useLocation();
  const imagePath = process.env.PUBLIC_URL + "/confirmation_illustration.svg";
  const tickIconPath = process.env.PUBLIC_URL + "/confirmation_tick.svg";
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const invoiceNumber = location.state?.invoiceNumber;
  const eventData = location.state?.eventData;
  const totalPrice = location.state?.totalPrice;
  const ticketSelections = location.state?.purchasedTickets;
  const ticketOptions = location.state?.ticketOptions;
  const userDetails = location.state?.userDetails;
  const receipt = location.state?.attachment;
  // const [userDetails, setUserDetails] = useState(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);

  const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       try {
  //         const response = await axios.post(`${apiUrl}/auth/verify-token`, {
  //           token,
  //         });
  //         if (response.status === 200) {
  //           setUserDetails(response.data.userDetails);
  //           console.log(response.data.userDetails);
  //         } else {
  //           console.log("Failed to verify token");
  //           setUserDetails(undefined);
  //         }
  //       } catch (error) {
  //         console.log("Error verifying token:", error);
  //         setUserDetails(undefined);
  //       }
  //     } else {
  //       setUserDetails(undefined);
  //     }
  //   };

  //   fetchUserDetails();
  // }, []);

  useEffect(() => {
    let interval;
    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled, timer]);

  const sendTickets = async () => {
    try {
      // Generate PDF Blob for ticket
      const pdfBlob = await pdf(
        <PdfTicket
          invoice={invoiceNumber}
          eventData={eventData}
          ticketSelections={ticketSelections}
          ticketOptions={ticketOptions}
          userDetails={userDetails}
        />
      ).toBlob();

      const emailData = new FormData();
      emailData.append("payment_id", ticketSelections[0].paymentID);
      emailData.append("user_email", userDetails?.email);
      emailData.append("attachment_pdf", pdfBlob, "ticket.pdf"); // Append Blob directly, not Base64

      await sendEmail(emailData);
    } catch (error) {
      console.error("Error generating ticket PDF:", error);
    }
  };

  // Function to send the email
  const sendEmail = async (emailData) => {
    try {
      await axios.post(`${apiUrl}/ticket/user-email`, emailData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error sending user email:", error);
    }
  };

  useEffect(() => {
    sendTickets();
  }, []);

  const calculateTotalPrice = () => {
    let total = 0;
    console.log(ticketSelections);
    console.log(ticketOptions);
    ticketSelections.forEach((selection) => {
      const price = ticketOptions.find(
        (option) => option.id === selection.ticketType
      ).price;
      total += parseFloat(price);
    });
    return total;
  };

  const handleOpenPdf = async () => {
    // let ticketData = [];
    // ticketSelections.map((ticket) => {
    //   const price = ticketOptions.find(option => option.id === ticket.id).price;
    //   for (let i = 0; i < ticket.qty; i++) {
    //     console.log(ticket.id);
    //     ticketData.push({'id': ticket.id, 'price': price})
    //   }
    // })
    // console.log(ticketData);
    const doc = (
      <PdfTicket
        invoice={invoiceNumber}
        eventData={eventData}
        ticketSelections={ticketSelections}
        ticketOptions={ticketOptions}
        userDetails={userDetails}
      />
    );
    const asBlob = await pdf(doc).toBlob();
    const pdfUrl = URL.createObjectURL(asBlob);
    window.open(pdfUrl, "_blank");
  };

  const handleResendTickets = () => {
    sendTickets();
    setIsResendDisabled(true);
    setTimer(60);
    // Add logic to resend tickets here
  };

  return (
    <>
      <Header />
      <div className="purchase-confimation-page-wrapper">
        <div className="purchase-confirmation-page-container">
          <img
            src={imagePath}
            className="confirmation-illustration"
            alt="confirmation-illustration"
          />
          <h6 className="heading">Tickets have been sent to</h6>
          <h6
            className="email"
            onClick={() => navigator.clipboard.writeText(userDetails?.email)}
          >
            {userDetails?.email}
          </h6>

          <div className="purchase-confirmation-details">
            <img src={tickIconPath} alt="tick-illustration" />
            <h6 className="heading">Congratulations!</h6>
            <button
              className="download-e-ticket-button"
              onClick={handleOpenPdf}
            >
              Download e-Ticket
            </button>
            <div className="purchase-status-details">
              <div className="status-button">
                <h6>CONFIRMED</h6>
              </div>
              <div className="date-time">
                <img src={iconsPath + "calendar-black.svg"} alt="tick-icon" />
                <p>{eventData?.date}</p>
              </div>
              <div className="date-time">
                <img
                  src={iconsPath + "calendar-black.svg"}
                  alt="calendar-icon"
                />
                <p>{eventData?.time}</p>
              </div>
            </div>
            <h6 className="heading">{eventData?.name}</h6>
            <h6 className="amount-paid">
              Paid <b>PKR {calculateTotalPrice()}</b>
            </h6>
            {/* <button className="cancel-booking-button">
              Cancel your booking
            </button> */}
            <h6 className="heading">
              Need to cancel?{" "}
              <a href="/account?tab=contact" className="contact-us-redirect">
                Contact us
              </a>
            </h6>
            <h6 className="heading">No refunds/only credits</h6>
            <h6 className="heading">Haven't received your tickets yet?</h6>
            <button
              className={
                isResendDisabled
                  ? "resend-tickets-button-disabled"
                  : "resend-tickets-button"
              }
              onClick={handleResendTickets}
              disabled={isResendDisabled}
            >
              {isResendDisabled ? `Resend in ${timer}s` : "Resend Tickets"}
            </button>
            <div className="contact-details">
              <h6 className="heading">Having trouble receiving the tickets?</h6>
              <div>
                <h6
                  onClick={() => navigator.clipboard.writeText("0335 5880869")}
                >
                  0335 5880869
                </h6>
                <h6
                  onClick={() =>
                    navigator.clipboard.writeText("moqah.pk@gmail.com")
                  }
                >
                  moqah.pk@gmail.com
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PurchaseConfirmationPage;
