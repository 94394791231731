import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import LoadingIndicator from "./LoadingIndicator";

const AdminAccess = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const verifyToken = async () => {
      const tokenAdmin = localStorage.getItem("tokenAdmin");
      if (!tokenAdmin) {
        setIsAuthenticated(false);
        return;
      }
    
      try {
        const response = await axios.post(`${apiUrl}/admin-event/verify-token`, {
          tokenAdmin
        });
    
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        localStorage.removeItem("tokenAdmin");
        setIsAuthenticated(false);
      }
    };

    verifyToken();
  }, []);

  if (isAuthenticated === null) {
    return <LoadingIndicator />; // You can replace this with a loading spinner or something similar
  }

  return isAuthenticated ? children : <Navigate to="/admin-access" />;
};

export default AdminAccess;
