import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import TicketVerification from "../components/TicketVerification";
import ContactUs from "../components/ContactUs";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/HeaderAdmin";

const AdminPage = () => {
  const [eventID, setEventID] = useState('207'); // get event id from prev page
  const [activeTab, setActiveTab] = useState("dashboard");
  const [adminDetails, setAdminDetails] = useState(null); // Manages user details
  const [dashboardData, setDashboardData] = useState({});
  const [activityData, setActivityData] = useState({});
  const [attendeeData, setAttendeeData] = useState({});
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    if (tab) setActiveTab(tab);

    // Fetch Admin Details Only
    const fetchAdminDetails = async () => {
      const tokenAdmin = localStorage.getItem("tokenAdmin");
      if (tokenAdmin) {
        try {
          const response = await axios.post(`${apiUrl}/admin-event/verify-token`, {
            tokenAdmin,
          });
          if (response.status === 200) {
            setAdminDetails(response.data.adminDetails);
          } else {
            setAdminDetails(undefined);
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          setAdminDetails(undefined);
        }
      } else {
        setAdminDetails(undefined);
      }
    };

    fetchAdminDetails();
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/admin-event/dashboard`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ event_id: eventID })
      }
      try {
        const response = await axios.request(config);
        // console.log(response.data);
        setDashboardData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDashboardData();
  }, [eventID, apiUrl]);

  useEffect(() => {
    const fetchActivityData = async () => {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/admin-event/activity`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ event_id: eventID })
      }
      try {
        const response = await axios.request(config);
        // console.log(response.data);
        setActivityData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivityData();
  }, [eventID, apiUrl]);


  useEffect(() => {
    const fetchAttendeeData = async () => {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/admin-event/attendees`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ event_id: eventID })
      }
      try {
        const response = await axios.request(config);
        // console.log(response.data);
        setAttendeeData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAttendeeData();
  }, [eventID, apiUrl]);

  const getFormattedDate = () => {
    const today = new Date();
    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "2-digit",
    };
    return today.toLocaleDateString("en-GB", options).replace(",", ",");
  };

  // Render Content Based on Active Tab
  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <Dashboard data={dashboardData}/>;
      case "payment":
        return <TicketVerification activity={activityData} tickets={attendeeData} eventID={eventID}/>;
      case "contact":
        return (
          <div className="contact-container">
            <ContactUs />
          </div>
        );
      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <>
      <Header searchBar={false} />
      <div className="main-body account">
        <div className="account-page-container flex">
          {/* Sidebar */}
          <div className="vertical-sidebar">
            <button
              className={`icon-button ${
                activeTab === "dashboard" ? "active" : ""
              }`}
              onClick={() => setActiveTab("dashboard")}
            >
              <img
                className="navigation-icon"
                src={`${iconPath}${
                  activeTab === "dashboard"
                    ? "dashboard-active.svg"
                    : "dashboard-inactive.svg"
                }`}
                alt="dashboard-icon"
              />
            </button>
            <button
              className={`icon-button ${
                activeTab === "payment" ? "active" : ""
              }`}
              onClick={() => setActiveTab("payment")}
            >
              <img
                className="navigation-icon"
                src={`${iconPath}${
                  activeTab === "payment"
                    ? "ticketVerification-active.svg"
                    : "ticketVerification-inactive.svg"
                }`}
                alt="ticket-icon"
              />
            </button>
            <button
              className={`icon-button ${
                activeTab === "contact" ? "active" : ""
              }`}
              onClick={() => setActiveTab("contact")}
            >
              <img
                className="navigation-icon"
                src={
                  iconPath +
                  (activeTab === "contact"
                    ? "contact-us.svg"
                    : "contact-us-inactive.svg")
                }
                alt="contact-icon"
              />
            </button>
          </div>

          {/* Main Content */}
          <div className="main-content flex-1">
            <div className="greeting mb-4">
              {adminDetails ? (
                <h6 className="heading">Welcome, {adminDetails.first_name}</h6>
              ) : (
                <h6 className="heading">Welcome, Guest</h6>
              )}
              <h6 className="current-date">{getFormattedDate()}</h6>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminPage;
