import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import QRScannerDialog from "./QRScannerDialog";
import axios from "axios";
import { toast } from "react-toastify";
const TicketVerification = ({ tickets, activity, eventID }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  // State variables
  const [currentRows, setCurrentRows] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [activityData, setActivityData] = useState({
    totalAttendees: activity.total_tickets ?? 0,
    presentAttendees: activity.checked_in ?? 0,
    checkedOutAttendees: activity.checked_out ?? 0,
    canceledAttendees: activity.cancelled ?? 0,
  });
  const [ticketNumber, setTicketNumber] = useState("");
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const iconPath = process.env.PUBLIC_URL + "/icons/";

  useEffect(() => {
    if (tickets && Array.isArray(tickets)) {
      setTicketData(tickets);
      // console.log("Tickets loaded:", tickets); // Debug log
    }
  }, [tickets]);

  // useEffect(() => {
  //   // For Backend only
  //   /*
  //   const fetchTicketsAndActivityData = async () => {
  //     try {
  //       const ticketsResponse = await fetch("https://your-backend-url.com/api/tickets");
  //       const tickets = await ticketsResponse.json();
  //       setTicketData(tickets);

  //       const activityResponse = await fetch("https://your-backend-url.com/api/activity-tracking");
  //       const activity = await activityResponse.json();
  //       setActivityData(activity);
  //     } catch (err) {
  //       console.error("Error fetching data:", err.message);
  //       setError("Failed to fetch data. Please try again later.");
  //     }
  //   };

  //   fetchTicketsAndActivityData();
  //   */

  //   // Mock data for now
  //   setTicketData(mockTickets);
  // }, []);

  // Handle scan button click
  const handleScanClick = () => {
    setIsQRScannerOpen(true);
  };

  // Handle Ticket Verification
  const handleVerifyTicket = async (ticket_id, event_id, index) => {
    // const updatedTickets = ticketData.map((ticket) => {
    //   if (ticket.transaction_number === ticketNumber) {
    //     setError("");
    //     return { ...ticket, status: "Paid", validated: true };
    //   }
    //   return ticket;
    // });

    // const ticketFound = ticketData.some(
    //   (t) => t.transaction_number === ticketNumber
    // );
    // if (!ticketFound) setError("Transaction ID not found. Try again!");
    // setTicketData(updatedTickets);
    // setTicketNumber("");
    try {
      const response = await axios.post(`${apiUrl}/admin-event/verify-ticket`, {
        ticket_id,
        event_id,
      });
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        if (index !== null) {
          const updatedRows = [...currentRows];
          updatedRows[index].check_in = true;
          setCurrentRows(updatedRows);
        }
      }
    } catch (e) {
      // Extract error message from axios error object
      const errorMessage =
        e.response?.data?.message ||
        e.message ||
        "An error occurred while verifying the ticket";
      setError(errorMessage);
    }
    setTicketNumber("");
  };

  // Handle Payment Validation
  const handleValidatePayment = async (payment_id, event_id, index) => {
    try {
      const response = await axios.post(
        `${apiUrl}/admin-event/validate-payment`,
        { payment_id, event_id }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        if (index !== null) {
          const updatedRows = [...currentRows];
          updatedRows[index].paid = true;
          setCurrentRows(updatedRows);
        }
      }
    } catch (e) {
      // Extract error message from axios error object
      const errorMessage =
        e.response?.data?.message ||
        e.message ||
        "An error occurred while validating the payment";
      setError(errorMessage);
    }
    setTicketNumber("");
  };

  // Calculate the percentage of present attendees
  const calculatePercentage = () => {
    console.log(currentRows);
    if (activityData.totalAttendees > 0) {
      return (
        (activityData.presentAttendees / activityData.totalAttendees) *
        100
      ).toFixed(2);
    }
    return "0.00";
  };

  // Filter tickets based on search query
  // const filteredTickets = ticketData.filter(
  //   (ticket) =>
  //     ticket.ticket_id.toString().includes(searchQuery) ||
  //     ticket.payment_id.includes(searchQuery) ||
  //     ticket.ticket_type.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const filteredTickets = useMemo(() => {
    // console.log("Filtering tickets. Current data:", ticketData); // Debug log

    if (!Array.isArray(ticketData) || ticketData.length === 0) {
      // console.log("No ticket data to filter"); // Debug log
      return [];
    }

    if (!searchQuery) {
      // console.log("No search query - returning all tickets"); // Debug log
      return ticketData;
    }

    const lowercaseQuery = searchQuery.toLowerCase();
    return ticketData.filter((ticket) => {
      if (!ticket) return false;

      return (
        ticket.ticket_id?.toString().includes(lowercaseQuery) ||
        ticket.payment_id?.toString().includes(lowercaseQuery) ||
        ticket.ticket_type?.toLowerCase().includes(lowercaseQuery)
      );
    });
  }, [ticketData, searchQuery]);

  // Pagination Logic
  // const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // setCurrentRows(filteredTickets.slice(indexOfFirstRow, indexOfLastRow));
  useEffect(() => {
    // console.log("Updating current rows. Filtered tickets:", filteredTickets); // Debug log
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const newRows = filteredTickets.slice(indexOfFirstRow, indexOfLastRow);
    // console.log("New rows:", newRows);
    setCurrentRows(newRows);
  }, [filteredTickets, currentPage]);
  const totalPages = Math.ceil(filteredTickets.length / rowsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Handle Report Download (CSV Logic)
  const handleDownloadReport = () => {
    const csvData = [
      [
        "Ticket ID",
        "Ticket Status",
        "Ticket Type",
        "Purchase Time",
        "Payment ID",
        "Validation Status",
      ],
      ...ticketData.map((ticket) => [
        ticket.ticket_id,
        ticket.check_in ? "Checked In" : "Unchecked",
        ticket.ticket_type,
        ticket.payment_date,
        ticket.payment_id,
        ticket.paid ? "Validated" : "Not Validated",
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");
    const link = document.createElement("a");
    link.href = encodeURI(csvContent);
    link.download = "ticket_report.csv";
    link.click();
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen flex flex-col gap-6">
      {/* Activity Tracking Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="font-bold text-gray-800 mb-4">Activity Tracking</h3>
        <div className="relative w-full bg-gray-200 h-4 rounded-full">
          <div
            className="absolute top-0 left-0 h-4 bg-green-500 rounded-full"
            style={{ width: `${calculatePercentage()}%` }}
          ></div>
        </div>
        <p className="text-green-700 font-bold mt-2">
          {calculatePercentage()}%
        </p>
        <p className="text-lg font-bold mt-4">
          Total Attendees: {activityData.totalAttendees}
        </p>
        <div className="flex flex-wrap gap-4 mt-4">
          <div className="bg-green-100 p-2 rounded text-green-600">
            Present attendees: {activityData.presentAttendees}
          </div>
          <div className="bg-yellow-100 p-2 rounded text-yellow-600">
            Checked out attendees: {activityData.checkedOutAttendees}
          </div>
          <div className="bg-red-100 p-2 rounded text-red-600">
            Canceled attendees: {activityData.canceledAttendees}
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="flex flex-wrap gap-4 justify-center">
        <button
          onClick={handleDownloadReport}
          className="bg-gray-200 flex items-center justify-center gap-2 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
        >
          <img
            src={`${iconPath}report-icon.svg`}
            alt="Report Icon"
            className="w-5 h-5"
          />
          Report
        </button>
        <button
          className="bg-blue-600 flex items-center justify-center gap-2 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition shadow"
          onClick={handleScanClick}
        >
          <img
            src={`${iconPath}scan-icon.svg`}
            alt="Scan Icon"
            className="w-5 h-5"
          />
          Scan Now
        </button>
      </div>

      <QRScannerDialog
        isOpen={isQRScannerOpen}
        onClose={() => setIsQRScannerOpen(false)}
      />

      {/* Verify Ticket */}
      <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
        <label className="block text-gray-700 font-medium mb-2">
          Enter your Ticket Number
        </label>
        <input
          type="text"
          placeholder="123234546897"
          value={ticketNumber}
          onChange={(e) => setTicketNumber(e.target.value)}
          className="border border-gray-300 px-3 py-2 rounded-lg w-full max-w-md focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() => handleVerifyTicket(ticketNumber, eventID, null)}
          className="bg-gray-200 text-gray-700 px-4 py-2 mt-2 rounded-lg hover:bg-gray-300 transition w-full max-w-md"
        >
          Verify
        </button>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>

      {/* Tickets Table */}
      <div className="mt-6 bg-white p-4 rounded-lg shadow overflow-x-auto">
        <h3 className="text-lg font-bold text-gray-800 mb-4">List of Users</h3>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search by ID, Type, Payment ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 px-3 py-2 rounded-lg w-full max-w-sm focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="border p-2">Ticket ID</th>
              <th className="border p-2">Ticket Status</th>
              <th className="border p-2">Ticket Type</th>
              <th className="border p-2">Purchase Time</th>
              <th className="border p-2">Payment ID</th>
              <th className="border p-2">Validation Status</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((ticket, index) => (
              <tr key={ticket.ticket_id} className="even:bg-gray-50">
                <td className="border p-2 text-center">{ticket.ticket_id}</td>
                <td className="border p-2 text-center">
                  {ticket.check_in ? "Checked In" : "Not Checked In"}
                </td>
                <td className="border p-2 text-center">{ticket.ticket_type}</td>
                <td className="border p-2 text-center">
                  {new Date(ticket.payment_date).toLocaleString()}
                </td>
                <td className="border p-2 text-center">{ticket.payment_id}</td>
                <td className="border p-2 text-center">
                  {ticket.paid ? (
                    <button>
                      <span className="text-green-500">✔</span>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        handleValidatePayment(ticket.payment_id, eventID, null)
                      }
                    >
                      <span className="text-red-500">✖</span>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="bg-gray-200 px-3 py-1 rounded hover:bg-gray-300"
          >
            Prev
          </button>

          <div className="flex space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 hover:bg-gray-300"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="bg-gray-200 px-3 py-1 rounded hover:bg-gray-300"
          >
            Next
          </button>
        </div>
        <div className="flex justify-end mt-2">
          <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            className="pagination-dropdown"
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TicketVerification;
