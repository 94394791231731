import React, { useState } from "react";
import QrScanner from "react-qr-scanner";
import axios from "axios";
import Lottie from "lottie-react";
import crossAnimation from "../animations/cross-animation.json";
import tickAnimation from "../animations/tick-animation.json";

const QRScannerDialog = ({ isOpen, onClose }) => {
  const [scanning, setScanning] = useState(true);
  const [result, setResult] = useState(null);
  const [valid, setValid] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [qrData, setQrData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleScan = async (data) => {
    if (data) {
      setScanning(false);
      setLoading(true);

      try {

        const response = await axios.post(`${apiUrl}/admin-event/verify-qr`, {
          qr_code: data.text,
        });
        console.log(response.data);
        setQrData(JSON.parse(data.text));
        setResult(response.data.message);
        setValid(response.data.valid);
      } catch (err) {
        setError("Error processing QR code. Please try again.");
        console.error("API Error:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError(
      "Error accessing camera. Please make sure you have granted camera permissions."
    );
  };

  const handleScanAgain = () => {
    setScanning(true);
    setResult(null);
    setValid(null);
    setError(null);
    setQrData(null);
  };

  const handleClose = () => {
    setScanning(true);
    setResult(null);
    setValid(null);
    setError(null);
    setQrData(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="qr-scanner-dialog-overlay">
      <div className="qr-scanner-dialog">
        <button className="close-button" onClick={handleClose}>
          ×
        </button>
        {scanning ? (
          <div className="scanner-view">
            <h2 className="scan-heading">Scan QR Code</h2>
            <div className="qr-reader-container">
              <QrScanner
                onScan={handleScan}
                onError={handleError}
                constraints={{
                  video: { facingMode: "environment" },
                }}
                style={{ width: "100%" }}
              />
            </div>
            {error && <p className="error-message">{error}</p>}
          </div>
        ) : (
          <div className="result-view">
            {loading ? (
              <div className="loading-spinner">Loading...</div>
            ) : (
              <>
                <h2 className="result-heading">Scan Result</h2>
                {result && valid && (
                  <div className="result-container">
                    <div className="result-icon">
                        <Lottie animationData={tickAnimation} loop={false} />
                        </div>
                    <div className="result-content">
                      <p>Ticket ID: {qrData.ticket_id}</p>
                      <p>Event ID: {qrData.event_id}</p>
                      <p>{result}</p>
                    </div>
                  </div>
                )}
                {result && !valid && (
                  <div className="result-container">
                  <div className="result-icon">
                    <Lottie animationData={crossAnimation} loop={false} />
                  </div>
                  <p className="error-message">{result}</p>
                </div>
                )}
                {error && (
                  <div className="result-container">
                    <div className="result-icon">
                      <Lottie animationData={crossAnimation} loop={false} />
                    </div>
                    <p className="error-message">{error}</p>
                  </div>
                )}
                <button className="scan-again-button" onClick={handleScanAgain}>
                  Scan Again
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QRScannerDialog;
